<template>
  <b-row class="justify-content-center">
    <b-col cols="12" md="8" lg="6">
      <b-card class="text-center">
        <b-card-body class="p-4">
          <b-row>
            <b-col cols="12">
              <span v-if="this.clock_status === 'Off'">
                Hello {{ driverName }},
                <br>
                <span v-if="this.start_time !== null">
                  Your next scheduled shift is {{ start_time }}
                </span>
                <span v-else>
                  Your next scheduled shift is .......
                </span>
              </span>
              <span v-if="this.clock_status === 'ON'">
                {{ driverName }}, you have been on shift for: {{ this.total_time }}
              </span>
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col cols="12">
              <feather-icon
                icon="ClockIcon"
                size="150"
                :fill="this.color === 'grey' ? 'grey' : this.color"
                stroke="white"
                @click="this.color !== 'grey' && clockUpdate($event)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              Select clock to go {{ this.clock_status === 'Off' ? 'On' : 'Off' }} Duty
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col cols="6">
              <router-link class="nav-link" to="/request-time-off">
                <b-button type="button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                  Time-Off Requests
                </b-button>
              </router-link>
            </b-col>
            <b-col cols="6">
              <router-link class="nav-link" to="/clock-history">
                <b-button type="button" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                  Past 7 Days
                </b-button>
              </router-link>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>
.text-center {
  text-align: center;
}
.nav-link {
  display: flex;
  justify-content: center;
}
</style>

  
<script>
import { BRow, BCol, BButton, BCard, BCardBody } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import axios from "axios";
import { serverUri } from "@/config";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment  from "moment";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
  },
  data() {
    return {
      data: {},
      startDate:moment(new Date()).format("MM/DD/YYYY"),
      color: "grey",
      clock_status: "Off",
      clock_in: '',
      total_time:0,
      start_time:null,
      userData: getUserData(),
      userId: getUserData().id,
      companyId: getUserData().company_id,
      driverName: getUserData().nick_name == '' || getUserData().nick_name == undefined ? getUserData().username.toUpperCase() : getUserData().nick_name.toUpperCase(),
    };
  },

  computed: {
				// formattedTime(total_time) {
				// 	const hours = Math.floor(total_time / 3600);
				// 	const minutes = Math.floor((total_time % 3600) / 60);
				// 	return `${hours} hours ${minutes} minutes`;
				// }
			},
  mounted() {
    setInterval(() => {

      this.getTotalTime();
     
    }, 60000);
  },
  created() {

    // get start time of company
    this.$http
        .get(`${serverUri}/drivers/details/${this.userData.driver_id}`)
        .then((response) => {
          this.data = response.data;

          this.start_time = moment(this.data[0].start_time_new).format("MM/DD/YYYY HH:mm:ss");
      
        });

    // data
   this.getClockStatus();
   this.getTotalTime();
  },
  methods: {
    formattedTime(total_time) {
      if(isNaN(total_time))
      {
        return `0 hours and 0 minutes`;
      } else {
					const hours = Math.floor(total_time / 3600);
					const minutes = Math.floor((total_time % 3600) / 60);
          return `${hours} hours and ${minutes} minutes`;

          // if(hours < 0 && minutes < 0)
          // {
          //   console.log(';;;');
					//   return `0 hours and 0 minutes`;
          // } else {
          //   return `${hours} hours and ${minutes} minutes`;
          // }
			}
    },
        getTotalTime()
        {
              this.$http
            .post(`${serverUri}/driver/total-time`, {
              company_id:this.companyId,
              driver_id: this.userId,
            })
            .then((response) => {
              const Time = response.data.data.total_time == null ? 0 : response.data.data.total_time;
              const DutyStatus = response.data.data.duty;
              const startTime  = response.data.data.start_time;
              if(DutyStatus == 'ON')
                {
                  const StartTime  =  moment(startTime).format("DD/MM/YYYY HH:mm:ss");

                  console.log('1');
                  const EndTime    =  response.data.data.end_time;

                  const totalTime  =  moment.utc(moment(EndTime,"DD/MM/YYYY HH:mm:ss").diff(moment(StartTime,"DD/MM/YYYY HH:mm:ss"))).format("X");              

                  const AllTotalTime =  parseInt(Time) + parseInt(totalTime);

                  this.total_time = this.formattedTime(AllTotalTime)

                  // check 13 hours 
                  if(AllTotalTime >= 46800)
                  {
                      this.$swal({
                                  icon: "success",
                                  title: "13 Hours Completed",
                                  text: "Are you still working OR forgot to clockout",
                                  customClass: {
                                      confirmButton: "btn btn-success",
                                  },
                              });
                  }

                } else {     
                  
                  console.log('2');
                  const AllTotalTime =  parseInt(Time);

                  this.total_time = this.formattedTime(AllTotalTime)

                }
            });
        },
    getClockStatus() 
    {
      // get clock status
      this.$http
        .post(`${serverUri}/driver/clockStatus`, {
          company_id:this.companyId,
          driver_id: this.userId,
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == false) {
            this.color = "#76bb6c";
            this.clock_status = "Off";
          } else {
            this.clock_in = response.data[0].clock_in;
            this.color        = "#e15b5b";
            this.clock_status = "ON";
          }
        });
    },
    
  
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    clockUpdate(event) {
      // console.log(event);
      axios
        .post(`${serverUri}/driver/clockUpdate`, {
          company_id:this.companyId,
          driver_id: this.userId,
        })
        .then((res) => {
          if (res.data.status) 
          {
            this.getClockStatus();
            this.clock_status = res.data.data.duty;
            this.color = res.data.data.color;
            const Type = this.clock_status != "Off" ? "Start" : "Stop";
            this.showToast("success", Type, res.data.message);
          }
        });
    },
  },
};
</script>
  